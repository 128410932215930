.contact-container {
    padding: 4vw 8vw;
    width: 76vw;
    font-size: 14px;
    line-height: 64px;
}

.contact-sentence {
    font-weight: 400;
}

.input {
    border: none;
    border-bottom: 2px solid var(--black);
    color: var(--black);
    background-color: var(--main-color);
    font-weight: 400;
    font-family: var(--main-font);
    font-size: 28px;
    line-height: 4px;
    padding: 3px;
    border-radius: 2px;
    outline-offset: 7px;
}

.get-in-touch {
    position: absolute;
    top: 45px;
    font-weight: 100;
    font-size: 28px;
    line-height: 44px;
}

.schedule {
    position: absolute;
    top: 90px;
    font-weight: 600;
    font-size: 28px;
    line-height: 44px;
}

.placeholder {
    color: aqua;
}

.send-btn {
    border-radius: 20px;
    border: none;
    width: 400px;
    color: var(--main-color);
    background-color: var(--secondary-color);
    font-size: 40px;
    font-weight: 700;
    padding: 10px 30px;
    margin-top: 6vw;
    transition: all .2s ease-in-out;
    font-family: 'Reem Kufi Variable', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &:hover {
        cursor: pointer;
        outline-offset: 2px;
        outline: 2px solid var(--black);
        background-color: var(--black);
    }

}