.logo {
    margin: 0 10px;
    margin-top: -20px;
    height: calc(50px + 6vw);
}
.containerCenter {
    position: relative;
    z-index: 2;
    height: 70px;
    padding: 40px 40px;
    display: flex;
    justify-content: space-between;
}