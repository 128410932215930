/* ScrollIndicator.css */

.scroll-indicator {
    width: 40px;
    height: 40px;
    background-color: var(--secondary-color);
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    left: 47.3%;
    animation: bounce 2s infinite alternate, fadeOut 6s 8s forwards;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }

  .scroll-arrow {
    transform: rotate(-90deg);
    width: 21px;
  }

  @media (max-width:801px)  { 
    .scroll-indicator{
        left: 50%;
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  @keyframes fadeOut {
    0%, 90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
  