/* Carousel.css */
.carousel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .carousel-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    position: relative;
    transition: transform 0.5s, opacity 0.5s;
  }
  
  .carousel-content.hidden-left {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  .carousel-content.hidden-right {
    transform: translateX(100%);
    opacity: 0;
  }
  
  .text {
    width: 30vw;
    padding: 0 20px;
    margin: 0 40px;
  }

  .title-right {
    margin-left: 20vw;
  }

  .title-left {
    margin-right: 10vw;
  }
  
  .image {
    flex: 1;
    display: flex;
    height: 24vw
  }

  .img-left {
    width: 15vw;
    margin-left: 60px;
  }
  
  .img-right {
    margin-right: -180px;
  }

  .text-left {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .text-right {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  
  .carousel-buttons {
    margin-top: 10px;
  }
  
  .carousel-buttons button {
    margin: 0 5px;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .carousel-buttons button.active {
    background-color: #ccc;
  }
  
  .carousel-navigation {
    position: relative;
    width: 100vw;
    margin-top: 10px;
  }
  
  .carousel-navigation button {
    scale: .7;
    padding: 16px 15px 14px;
    border-radius: 50px;
    background-color: rgba(147, 142, 122, 0.543);
    border: none;
    cursor: pointer;
    transition: filter .15s ease-in-out;

    &:hover {
        filter: invert();
    }
  }

  .prev {
    position: absolute;
    top: -260px;
    left: 5vw;
  }

  .next {
    position: absolute;
    top: -260px;
    right: 5vw;
    transform: rotateY(180deg);
  }
  
  .carousel-navigation button:not(:last-child) {
    margin-right: 10px;
  }

  .experience-company {
    font-weight: 300;
    font-size: 2vw;
    line-height: 1em;
  }
  .company-left {
    width: 27vw;
  }
  .company-right {
    width: 24vw;
  }
  .experience-title {
    font-weight: 300;
    font-size: 1.6vw;
    line-height: 1em;
    margin-bottom: 1.8vw;
  }
  .experience-desc {
    font-size: 1.2vw;
    font-weight: 100;
    line-height: 2em;
  }

  @media (max-width:801px)  { 

    .carousel-content {
      margin-top: calc(180px - 20vw); /* The margin grows as the viewport width decreases */
      margin-top: auto;
    }

    .title-right {
      margin-left: 9vw;
    }
  
    .title-left {
      margin-right: 10vw;
    }
      
    .img-left {
      margin-left: 0;
    }
    
    .img-right {
      width: 15vw;
      margin-right: -0;
    }
  
    .text-left {
      display: flex;
      margin-left: -15vw;
      width: 115%;
      justify-content: space-between;
    }
    
    .text-right {
      display: flex;
      width: 115%;
      margin-right: -3vw;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .experience-company {
      font-size: 4vw;
      line-height: 5.5vw;
      width: 150%;
    }
  
    .experience-title {
      font-size: 3vw;
      line-height: 4.5vw;
      width: 190%;
    }

    .experience-desc {
      font-size: 2vw;
      width: 140%;
    }

   }


  
  /* Additional styling as needed */
  