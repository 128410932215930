.hamburger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  padding: 10px;
  cursor: pointer;
  z-index: 51;
}

.absolute-ham {
  position: absolute;
  z-index: 51;
}

.fixed-ham {
  position: fixed;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: var(--black);
  transition: transform 0.3s ease-in-out;
}

.open .bar:nth-child(1) {
  background-color: var(--main-color);
  transform: rotateZ(45deg) translateY(11px);
}

.open .bar:nth-child(2) {
  background-color: var(--main-color);
  transform: scale(0);
}

.open .bar:nth-child(3) {
  background-color: var(--main-color);
  transform: rotateZ(-45deg) translateY(-11px);;
}


.hamburger-overlay {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: left 0.3s ease-in-out;
  z-index: 50;
}

.hamburger-overlay.open {
  left: 0;
}

.hamburger-overlay nav {
  width: 100vw; /* Adjust the width as needed */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hamburger-overlay a {
  color: var(--main-color);
  font-size: 4vw;
  margin-bottom: 35px;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.hamburger-overlay a:hover {
  color: var(--black);
}

@media (max-width:701px)  { 
  .hamburger-overlay a {
    font-size: 10vw;
  }
 }

