/* Add this CSS at the end of your existing stylesheet */
.custom-cursor {
    position: absolute;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 9999;
    transition: scale .7s ease-in-out, transform .1s ease-in-out;
    background-image: url('../../assets/photos/family-blue2.jpg');
    background-position: center;
    background-size: cover;
    opacity: 0.8;
  }

/* Add this CSS at the end of your existing stylesheet */
.custom-cursor {
  position: absolute;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9999;
  filter: invert();
  transform: translate(-50%, -50%);
  background-color: white;
  mix-blend-mode: difference;
  transition: scale .7s ease-in-out, transform .1s ease-in-out;
  opacity: 0.8;
}


  .inactive {
    scale: 0;
}