/* HERO SECTION */
.hero {
    position: relative;
    display: flex;

    .titleCard {
        width: 50vw;

        .meetme {
            width: 32vw;
            margin-top: calc(300px - 20vw); /* The margin grows as the viewport width decreases */
            margin-right: -25%;
        }

        .mission {
            text-align: center;
            font-weight: 900;
            line-height: 1.8vw;
            font-size: .99vw;
            width: 20vw;
            margin-top: 100px; /* The margin grows as the viewport width decreases */
            font-family: 'Reem Kufi Variable', sans-serif;
        }
    }
    
    .servicesCard {
        position: relative;
        overflow: hidden;
        color: var(--main-color);
        padding-left: 40px;
        font-size: 4.1vw;
        font-weight: 100;
        width: 50vw;
        height: 100vh;
        background: url('../../assets/photos/paper.jpg');
        background-image: url('../../assets/photos/paper.jpg');
        background-size: cover;
    }

}
/* HERO SECTION END*/

/*  PORTFOLIO GRID SECTION */
.portfolioGrid {
    background-color: var(--black);
    min-height: 95vh;
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding: 240px 6vw 120px;
    grid-column-gap: 1vw;
    grid-row-gap: 1vw;
    }

.portfolioGrid > * {
    border-radius: 40px;
}

.portraitVidContainer {
    position: relative;
    width: 100%;
  }

  .p0 {
    position: absolute;
    font-size: 64px;
    font-weight: 900;
    color: var(--main-color);
    top: 80px;
    left: 90px;
    z-index: 10;
    overflow: visible;
}
    
.p1 {
    background-image: url('../../assets/photos/wedding.png');
    background-size: cover;
    position: relative;
    overflow: hidden;
    grid-area: 1 / 1 / 2 / 3; 
}

.p1 > * {
    height: 100%;
}


.p1Child {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    font-size: 3vw;
    color: var(--main-color);
    background: rgba(89, 102, 113, 0.70);
    backdrop-filter: blur(10px);
    pointer-events: none;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p1:hover {
    outline-offset: 2px;
    outline: 6px solid var(--main-color)
}

.p1:hover .p1Child {
    opacity: 1;
}


.p2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/photos/space.png');
    background-size: cover;
    position: relative;
    overflow: hidden;
            grid-area: 1 / 3 / 2 / 4; 
}

.p2 img {
    width: 20vw;
    max-width: 200px;
    height: auto;
}

.p2Child {
    position: absolute;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    height: 100%;
    font-size: 3vw;
    color: var(--main-color);
    background: rgba(89, 102, 113, 0.70);
    backdrop-filter: blur(10px);
    pointer-events: none;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p2:hover {
    outline-offset: 2px;
    outline: 6px solid var(--main-color)
}

.p2:hover .p2Child {
    opacity: 1;
}




.p3 {
    position: relative;
    overflow: hidden;
    background-image: url('../../assets/photos/kaiser.png');
    background-position: center;
    background-size: cover;
    grid-area: 2 / 1 / 4 / 2; 
        & > * {
            width: 100%;
            height:100%;
        }
}

.p3Child {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    font-size: 3vw;
    color: var(--main-color);
    background: rgba(89, 102, 113, 0.70);
    backdrop-filter: blur(10px);
    pointer-events: none;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p3:hover {
    outline-offset: 2px;
    outline: 6px solid var(--main-color)
}

.p3:hover .p3Child {
    opacity: 1;
}


.p4 {
        background-image: url('../../assets/photos/desert.png');
        background-size: cover;
        position: relative;
        overflow: hidden;
        grid-area: 2 / 2 / 3 / 3; 
        & > * {
            height: 100%;
            width: 100%;
        }
}

.p4Child {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    font-size: 3vw;
    color: var(--main-color);
    background: rgba(89, 102, 113, 0.70);
    backdrop-filter: blur(10px);
    pointer-events: none;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p4:hover {
    outline-offset: 2px;
    outline: 6px solid var(--main-color)
}

.p4:hover .p4Child {
    opacity: 1;
}

.p5 {
        position: relative;
        overflow: hidden;
        background-image: url('../../assets/photos/extrahop.png');
        background-size: cover;
        grid-area: 3 / 2 / 4 / 3; 
        & > * {
            width: 100%;
            height: 100%;
        }
}

.p5Child {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    font-size: 3vw;
    color: var(--main-color);
    background: rgba(89, 102, 113, 0.70);
    backdrop-filter: blur(10px);
    pointer-events: none;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p5:hover {
    outline-offset: 2px;
    outline: 6px solid var(--main-color)
}

.p5:hover .p5Child {
    opacity: 1;
}

.p6 {
        background-image: url('../../assets/photos/lwc.png');
        background-size: cover;
        position: relative;
        background-position: center;
        overflow: hidden;
        grid-area: 2 / 3 / 4 / 4; 
        & > * {
            width: 100%;
            height: 100%;
        }
}

.p6Child {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    font-size: 3vw;
    color: var(--main-color);
    background: rgba(89, 102, 113, 0.70);
    backdrop-filter: blur(10px);
    pointer-events: none;
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p6:hover {
    outline-offset: 2px;
    outline: 6px solid var(--main-color)
}

.p6:hover .p6Child {
    opacity: 1;
}
/*  PORTFOLIO GRID SECTION END */

/* CAROUSEL SECTION */

.cSectionContainer {
    background-color: var(--black);
    min-height: 100vh;
    color: var(--main-color);
    padding: 8vw 15vw;

    .bio {
        font-size: 2.2vw;
        text-align: center;
        font-weight: 200;
        line-height: 4vw;
        min-height: 12vw;
        max-height: 12vw;
    }

    .bracket {
        width: 70vw;
    }
}

@media (max-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .hero {
        display: flex;
        flex-direction: column;

        .titleCard {
            width: 100vw;
            height: 100vh;

            .meetme {
                width: 60vw;
                margin-top: calc(180px - 20vw); /* The margin grows as the viewport width decreases */
            }

            .mission {
                text-align: center;
                font-weight: 900;
                line-height: 4.2vw;
                font-size: 2.5vw;
                width: 51vw;
                margin-top: 90px; /* The margin grows as the viewport width decreases */
            }
        }
        .servicesCard {
            width: 100vw;;
        }

        .servicesCard {
            padding-left: 0;
            font-size: 7vw;
        }
    }

    .portfolioGrid {
        min-height: 60vh;
        padding: 40px 6vw;
        grid-column-gap: 0;
        grid-row-gap: 0;
    
    }
    
    .portfolioGrid > * {
        scale: .8;
    }

    .p1 {
      margin: 0 -4vw;
    }

    .p3, .p6 {
        margin: -3vw 0;
    }

    .cSectionContainer {
        background-color: var(--black);
        min-height: 0;
        color: var(--main-color);
        padding: 8vw 15vw;
    }

    .p1Child, .p2Child, .p3Child, .p4Child, .p5Child, .p6Child {
        font-weight: 600;
        font-size: 4.5vw;
    }
    
}
@media (max-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
    .hero {
        .titleCard {
            .meetme {
                margin-top: 40px;
                width: 86vw;
            }

            .mission {
                line-height: 8vw;
                font-size: 3.5vw;
                width: 72vw;
                margin-top: 90px; /* The margin grows as the viewport width decreases */
            }
        }
    }
}

@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
