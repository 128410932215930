.footer-container {
    background-color: var(--main-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 120px 50px;
  }
  
  .footer-left {
    display: flex;
    flex-direction: column;
  }
  
  .footer-h2 {
    color: var(--black);
    width: 900px;
    font-size: 49px;
    font-weight: 400;
    margin: 0;
  }
  
  .call-cta {
    color: var(--black);
    margin-top: 40px;
    font-weight: 100;
    font-size: 29px;
  }
  
  .footer-connect {
    text-align: center;
    text-decoration: none;
    border-radius: 20px;
    border: none;
    width: 500px;
    color: var(--main-color);
    background-color: var(--secondary-color);
    font-size: 60px;
    font-weight: 700;
    padding: 10px 0;
    margin: 0;
    transition: all .2s ease-in-out;

    &:hover {
        cursor: pointer;
        outline-offset: 2px;
        outline: 2px solid var(--black);
        background-color: var(--black);
    }
  }
  
  .bottom-nav {
    margin: 35px 0;
    width: 15vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bottom-nav-item {
    color: var(--black);
    font-size: 24px;
    font-weight: 400;
    text-decoration: none;
    transition: all .1s ease-in-out;
    
    &:hover {
        padding-bottom: 3px;
        border-bottom: 1px solid var(--black);    
    }
  }

  .current-page {
    padding-bottom: 3px;
    border-bottom: 1px solid var(--black);
  }
  
  .footer-logo {
    cursor: pointer;
    position: relative;
    width: 140px;
  }


  
  .logo-lisence-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .lisenced-bonded-div {
    display: flex;
    width: 330px;
    justify-content: space-between;
    align-items: center;
    margin-top: 120px;
    font-size: 9px;
    font-weight: 900;
    color: var(--black);
    text-transform: uppercase;
  }

  
  .L-B-I {
    line-height: 25px;
  }
  
  .badge {
    height: 30px;
    border-radius: 100px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* horizontal-offset vertical-offset blur-radius color */
  }

  .bottom-nav-fine {
    font-size: 11px;
    color: var(--black);
    font-weight: 400;
    margin: 5px 0 10px;
  }
  
  .bottom-push {
    margin: 0 0 -20px 0;
  }
  
  
  
  @media (max-width: 1320px) {
  
    .footer-container {
      position: relative;
      background-color: var(--main-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 20px;
    }
    
    .footer-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    
    .footer-h2 {
      color: var(--black);
      font-size: 5.5vw; /* Adjust font size relative to viewport width */
      font-weight: 400;
      margin: 0;
      width: 90%; /* Adjust width relative to parent container */
      max-width: 400px;
    }
    
    .call-cta {
      color: var(--black);
      margin-top: 20px;
      font-weight: 100;
      font-size: 3vw; /* Adjust font size relative to viewport width */
    }
    
    .footer-connect {
        border-radius: 20px; 
        scale: .8;
        width: 70vw;
        border: none;
      color: var(--main-color);
      font-size: 8vw; /* Adjust font size relative to viewport width */
      font-weight: 700;
      margin: 0;
      padding: 20px 0;
    }
    
    .bottom-nav {
      margin: 25px 0;
      width: 90%; /* Adjust width relative to parent container */
      max-width: 400px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }

  
    
    .bottom-nav-item {
      color: var(--black);
      font-size: 2.5vw; /* Adjust font size relative to viewport width */
      font-weight: 400;
    }
    
    
    .footer-logo {
      width: 10vw; /* Adjust width relative to viewport width */
      max-width: 100%;
      margin-top: 20px;
    }
    
    .bottom-nav-fine {
      position: absolute;
      bottom: 20px;
      margin: 0;
      font-size: 1.5vw; /* Adjust font size relative to viewport width */
      color: var(--black);
      font-weight: 400;
    }
    
    .bottom-push {
      margin: 0 0 -15px;
    }

    .lisenced-bonded-div {
        flex-direction: column;
        margin: 30px;
        width: auto
    }
    
  }
  
  @media (min-width: 900px) and (max-width: 1320px) {
    .footer-h2 {
      color: var(--black);
      font-size: 3vw; /* Adjust font size relative to viewport width */
      font-weight: 400;
      margin: 0;
      width: 90%; /* Adjust width relative to parent container */
      max-width: 400px;
    }
    
    .call-cta {
      color: var(--black);
      margin-top: 40px;
      font-weight: 100;
      font-size: 2vw; /* Adjust font size relative to viewport width */
    }
    
    .footer-connect {
        border-radius: 20px;
        border: none;
      color: var(--main-color);
      font-size: 4vw; /* Adjust font size relative to viewport width */
      font-weight: 700;
      margin: 0;
      padding: 20px 0;
    }
    
    .bottom-nav {
      margin: 25px 0;
      width: 90%; /* Adjust width relative to parent container */
      max-width: 400px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
    }
    
    .bottom-nav-item {
      color: var(--black);
      font-size: 1.7vw; /* Adjust font size relative to viewport width */
      font-weight: 400;
    }
  
    .footer-logo {
      width: 10vw; /* Adjust width relative to viewport width */
      max-width: 100%;
      margin: 20px;
    }
  
    .bottom-nav-fine {
      position: absolute;
      bottom: 20px;
      margin: 10px;
      font-size: 1vw; /* Adjust font size relative to viewport width */
      color: var(--black);
      font-weight: 400;
    }
    
    .bottom-push {
      margin: 0 0 -1vw;
    }
  }